import React, { memo, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useGlobalContext } from "../../../context/GlobalContext";
import { useScroll } from "framer-motion";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Col, Container, Navbar } from "react-bootstrap";
import HeaderData from "./HeaderData";
import * as Yup from "yup";

import "../../../assets/scss/layouts/_header.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAll,
  selectHeaderRight,
  selectProductPath,
  selectSelectedMenu,
  setSelectedMenu,
} from "../../store/slices/navigationSlice";
import { registerComponent } from "./navigation/NavigationRegister";
import HorizontalParent from "./navigation/horizontal/HorizontalParent";
import HorizontalGroup from "./navigation/horizontal/HorizontalGroup";
import HorizontalItem from "./navigation/horizontal/HorizontalItem";
import HorizontalNavigation from "./navigation/horizontal/HorizontalNavigation";
import { selectLogos, setPageTitle } from "../../store/slices/configSlice";
import { getPageContent } from "../../store/slices/pageSlice";
import { selectCurrentLanguage, t } from "../../store/slices/languageSlice";
import _, { conforms } from "lodash";
import Utils from "../../../utils/Utils";
import { selectFooterNavi } from "../../store/slices/footerSlice";
import { Form, Formik } from "formik";
import { Input } from "../formFields/Form";
import useOnClickOutside from "../../../utils/UseOnClickOutside";
import { logoutUser, selectUser } from "../../store/slices/userSlice";
import {
  Autocomplete,
  Avatar,
  Box,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import {
  getProducts,
  getProductsSearchBar,
  selectProducts,
  selectProductsForSearch,
} from "../../pages/products/store/listSlice";

registerComponent("horizontal-parent", HorizontalParent);
registerComponent("horizontal-group", HorizontalGroup);
registerComponent("horizontal-item", HorizontalItem);

function needsToBeOpened(location, item, lang) {
  return location && isUrlInChildren(item, location.pathname, lang);
}

function isUrlInChildren(parent, url, lang) {
  if (!parent.subitems) {
    return false;
  }

  for (let i = 0; i < parent.subitems.length; i += 1) {
    const element = parent.subitems[i];

    const path = element.pathurl || element.path;

    if (element.subitems) {
      if (isUrlInChildren(element, url, lang)) {
        return true;
      }
    }
    if (!path) return false;

    if (path === url) {
      return true;
    }
  }

  return false;
}

export const Header = memo((props) => {
  const { setHeaderHeight } = useGlobalContext();
  const { scrollY } = useScroll();
  const [scrollPos, setScrollPos] = useState({
    y: 0,
    prevY: -1,
    directionDown: true,
  });
  const location = useLocation();

  useEffect(() => {
    let headerEl = document.querySelector("header");

    const setTopSpace = () => {
      let windowWidth = window.innerWidth,
        headerheight =
          props.topSpace.desktop && props.topSpace.desktop === true
            ? headerEl.offsetHeight
            : 0;

      if (windowWidth <= 1199 && props.topSpace.lg) {
        headerheight = props.topSpace.lg === true ? headerEl.offsetHeight : 0;
      }

      if (windowWidth <= 991 && props.topSpace.md) {
        headerheight = props.topSpace.md === true ? headerEl.offsetHeight : 0;
      }

      if (windowWidth <= 767 && props.topSpace.sm) {
        headerheight = props.topSpace.sm === true ? headerEl.offsetHeight : 0;
      }

      if (windowWidth <= 575 && props.topSpace.xs) {
        headerheight = props.topSpace.xs === true ? headerEl.offsetHeight : 0;
      }

      setHeaderHeight(headerheight);
    };

    setTopSpace();

    window.addEventListener("load", setTopSpace);
    window.addEventListener("resize", setTopSpace);

    if (document.body.classList.contains("mobile-menu")) {
      document.body.classList.remove("navbar-collapse-show");
      document.body.classList.remove("menu-modern");
      document.body.classList.remove("menu-full");
      document.body.style.removeProperty("overflow");
    }
  }, [location]);

  useEffect(() => {
    let lastScrollTop = 0;
    scrollY.on("change", (pos) => {
      if (pos > lastScrollTop) {
        setScrollPos({
          ...scrollPos,
          y: pos,
          prevY: pos - 1,
          directionDown: true,
        });
      } else {
        setScrollPos({
          ...scrollPos,
          y: pos,
          prevY: pos - 1,
          directionDown: false,
        });
      }

      if (pos === 0) {
        setScrollPos({ ...scrollPos, directionDown: true });
      }
      lastScrollTop = pos;
    });
  }, []);

  return (
    <header
      className={`${props.className ? props.className : ""}${
        scrollPos.y > 5 ? " sticky-header" : ""
      }${scrollPos.directionDown === false ? " header-appear" : ""}${
        props.type ? ` ${props.type}` : ""
      }`}
    >
      {props.children}
    </header>
  );
});

export const HeaderNav = (props) => {
  const handleMenuToggle = () => {
    let header = document.querySelector("header"),
      menu = header.querySelector(".navbar-nav"),
      menu_item = menu.querySelectorAll(".nav-item");

    // if (!document.body.classList.contains("navbar-collapse-show")) {
    //   document.body.classList.add("navbar-collapse-show");
    // } else {
    //   document.body.classList.remove("navbar-collapse-show");
    // }

    menu_item.forEach(function (item) {
      if (item.classList.contains("open")) {
        setTimeout(() => {
          item.classList.remove("open");
        }, 200);
      }
    });
  };

  return (
    <Navbar
      collapseOnSelect
      id="headerbar"
      expand={props.expand}
      bg={props.bg ? props.bg : "transparent"}
      variant={props.theme}
      className={`${props.menu && `menu-${props.menu}`}${
        props.className ? ` ${props.className}` : ""
      }${props.bg || props.bg === "transparent" ? "" : " header-transparent"}`}
      onToggle={handleMenuToggle}
    >
      <Container
        fluid={props.fluid}
        className={props.containerClass ? props.containerClass : ""}
      >
        {props.children}
      </Container>
    </Navbar>
  );
};

export const Menu = memo((props) => {
  const dispatch = useDispatch();
  const navigation = useSelector(selectAll);
  const navifooter = useSelector(selectFooterNavi);
  const naviright = useSelector(selectHeaderRight);
  const lang = useSelector(selectCurrentLanguage);
  const selectedMenu = useSelector(selectSelectedMenu);
  const [isMenuActive, setMenuActive] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const handleMenuClick = (e, index) =>
    setMenuActive(index !== isMenuActive ? index : null);

  const location = useLocation();

  useEffect(() => {
    navigation?.forEach((item) => {
      const url = item.pathurl || item.path;

      if (needsToBeOpened(location, item, lang)) {
        const navFind = Utils.getFlatNavigation([item]).find((f) => {
          const url = f.pathurl || f.path;

          return url === location.pathname;
        });

        if (navFind) {
          const name =
            navFind.title_lang && navFind.title_lang[lang]
              ? navFind.title_lang[lang]
              : "";

          dispatch(setPageTitle(name));

          dispatch(getPageContent({ item: navFind }));
          dispatch(setSelectedMenu(navFind));
        } else {
          const name =
            item.title_lang && item.title_lang[lang]
              ? item.title_lang[lang]
              : "";

          dispatch(setPageTitle(name));
          dispatch(getPageContent({ item }));
          dispatch(setSelectedMenu(item));
        }
      } else if (url === location.pathname) {
        const name =
          item.title_lang && item.title_lang[lang] ? item.title_lang[lang] : "";

        dispatch(setPageTitle(name));
        dispatch(getPageContent({ item }));
        dispatch(setSelectedMenu(item));
      }
    });

    navifooter?.forEach((item) => {
      const url = item.pathurl || item.path;

      if (needsToBeOpened(location, item, lang)) {
        const navFind = Utils.getFlatNavigation([item]).find((f) => {
          const url = f.pathurl || f.path;

          return url === location.pathname;
        });

        if (navFind) {
          dispatch(setSelectedMenu(navFind));
          dispatch(getPageContent({ item: navFind }));
        } else {
          dispatch(getPageContent({ item }));
          dispatch(setSelectedMenu(item));
        }
      } else if (url === location.pathname) {
        dispatch(getPageContent({ item }));
        dispatch(setSelectedMenu(item));
      }
    });

    naviright?.forEach((item) => {
      const url = item.pathurl || item.path;

      if (needsToBeOpened(location, item, lang)) {
        const navFind = Utils.getFlatNavigation([item]).find((f) => {
          const url = f.pathurl || f.path;

          return url === location.pathname;
        });

        if (navFind) {
          dispatch(setSelectedMenu(navFind));
          dispatch(getPageContent({ item: navFind }));
        } else {
          dispatch(getPageContent({ item }));
          dispatch(setSelectedMenu(item));
        }
      } else if (url === location.pathname) {
        dispatch(getPageContent({ item }));
        dispatch(setSelectedMenu(item));
      }
    });
  }, [navigation, location, navifooter, naviright]);

  useEffect(() => {
    let header = document.querySelector("header"),
      menu = header.querySelector(".navbar-nav"),
      menu_item = menu.querySelectorAll(".nav-item");

    // if (!document.body.classList.contains("navbar-collapse-show")) {
    //   document.body.classList.add("navbar-collapse-show");
    // } else {
    //   document.body.classList.remove("navbar-collapse-show");
    // }

    menu_item.forEach(function (item) {
      if (item.classList.contains("open")) {
        setTimeout(() => {
          item.classList.remove("open");
        }, 200);
      }
    });

    const tb = document.querySelector(".navbar-toggler");
    const nc = document.querySelector(".navbar-toggler-collapse");

    if (tb) {
      tb.classList.add("collapsed");
    }

    if (nc) {
      nc.classList.remove("show");
    }
  }, [location.pathname]);

  useEffect(() => {
    let navItems = document
      .querySelector("header")
      .querySelectorAll(".navbar-nav > .nav-item");
    navItems.forEach((nav) =>
      nav.addEventListener("mouseover", () => setIsHover(true))
    );
  }, []);

  return (
    <div
      className={`${props.mobileMenu ? `mobile-menu-${props.mobileMenu}` : ""}${
        props.className ? ` ${props.className}` : ""
      }`}
    >
      <HorizontalNavigation
        navigation={navigation}
        handleMenuClick={handleMenuClick}
        isMenuActive={isMenuActive}
        language={lang}
        selected={selectedMenu}
      />
    </div>
  );
});

export const NaviHeader = memo((props) => {
  const { dark_theme, light_theme } = useSelector(selectLogos);
  const menuSelected = useSelector(selectSelectedMenu);

  let type = "reverse-scroll";
  // MENU PRINCIPAL
  let theme = "dark";
  // BACKGROUND COLOR
  let bg = "";
  // SUBMENU (always light)
  let menu = "light";

  if (!_.isEmpty(menuSelected) && menuSelected?.website_menu_type_id) {
    const aa = menuSelected?.website_menu_type_id.split("_");

    type = aa[0] || "reverse-scroll";
    theme = aa[1] || "dark";
    bg = aa[2] || "";
    menu = aa[3] || "light";
  }

  return (
    <Header
      topSpace={{ md: true }}
      type={type}
      theme="light"
      bg="light"
      menu="light"
      className=""
    >
      <HeaderNav
        fluid="fluid"
        theme={theme}
        expand="lg"
        bg={bg}
        menu={menu}
        containerClass="sm:px-0"
        className="py-[0px] px-[15px] md:py-[18px] md:px-0 "
      >
        <Col sm={6} lg={2} className="col-auto me-auto ps-lg-0">
          <Link aria-label="header logo" className="flex items-center" to="/">
            {(!_.isEmpty(dark_theme) || !_.isEmpty(light_theme)) && (
              <Navbar.Brand className="inline-block p-0 m-0">
                <img
                  className="default-logo"
                  width="130"
                  height="36"
                  loading="lazy"
                  src={
                    theme === "dark"
                      ? dark_theme?.horizontal_logo
                      : light_theme?.horizontal_logo
                  }
                  data-rjs={
                    theme === "dark"
                      ? dark_theme?.horizontal_logo
                      : light_theme?.horizontal_logo
                  }
                  alt="logo"
                />
                <img
                  className="alt-logo"
                  width="130"
                  height="36"
                  loading="lazy"
                  src={
                    bg === "black"
                      ? dark_theme?.horizontal_logo
                      : light_theme?.horizontal_logo
                  }
                  data-rjs={
                    bg === "black"
                      ? dark_theme?.horizontal_logo
                      : light_theme?.horizontal_logo
                  }
                  alt="logo"
                />
                <img
                  className="mobile-logo"
                  width="130"
                  height="36"
                  loading="lazy"
                  src={
                    theme === "dark"
                      ? light_theme?.horizontal_logo
                      : light_theme?.horizontal_logo
                  }
                  data-rjs={
                    theme === "dark"
                      ? light_theme?.horizontal_logo
                      : light_theme?.horizontal_logo
                  }
                  alt="logo"
                />
              </Navbar.Brand>
            )}
          </Link>
        </Col>
        <div className="order-last px-[15px] hidden md:block">
          <Navbar.Toggle className="ml-[10px]">
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
            <span className="navbar-toggler-line"></span>
          </Navbar.Toggle>
        </div>

        <Navbar.Collapse className="col-auto col-lg-8 justify-center navbar-toggler-collapse">
          <Menu {...props} />
        </Navbar.Collapse>

        <Col className="col-auto col-lg-2 pe-0 md:!pl-[30px] xxs:!pl-[20px] flex items-center justify-end">
          <SearchBar className="xs:pl-[15px]" />
          <HeaderRight />
        </Col>
      </HeaderNav>
    </Header>
  );
});

export const HeaderRight = memo((props) => {
  const dispatch = useDispatch();
  const navi = useSelector(selectHeaderRight);
  const language = useSelector(selectCurrentLanguage);
  const { token } = useSelector(selectUser);
  const navigate = useNavigate();

  return navi.map((nav, _index) => {
    const { menu_item_tag } = nav;

    if (menu_item_tag === "reserved_area" && token)
      return (
        <div
          key={nav.menu_item_id}
          className={`header-language dropdown inline-block align-middle pl-[17px] text-[17px]${
            props.className ? ` ${props.className}` : ""
          }`}
          style={props.style}
        >
          <Link
            to="#"
            aria-label="language"
            onClick={(e) => e.preventDefault()}
          >
            <i
              onClick={() => dispatch(logoutUser(navigate))}
              className={`py-[30px] px-0 inline-block feather-log-out ${props.className}`}
            ></i>
          </Link>
        </div>
      );

    return (
      <div
        key={nav.menu_item_id}
        className={`header-language dropdown inline-block align-middle pl-[17px] text-[17px]${
          props.className ? ` ${props.className}` : ""
        }`}
        style={props.style}
      >
        <Link to="#" aria-label="language">
          <i
            className={`py-[30px] px-0 inline-block ${nav?.icon} ${props.className}`}
          ></i>
        </Link>
        {!_.isEmpty(nav.subitems) && (
          <ul className="dropdown-menu block absolute font-serif left-auto right-0 p-15px rounded-[6px] border-0 m-0 min-w-[140px]">
            {nav.subitems.map((s, _s) => {
              const url =
                s.url_lang && s.url_lang[language] ? s.url_lang[language] : "";

              // console.log(nav.menu_item_tag);

              const path = s.url_tabs
                ? s.url_tabs
                : url.substring(0, 1) === "/"
                ? url
                : `/${language}/${url}`;

              if (nav.menu_item_tag === "languages")
                return (
                  <li key={s.menu_item_id}>
                    <Link
                      aria-label="link"
                      title={s.title_lang && s.title_lang[language]}
                      onClick={(e) => {
                        e.preventDefault();
                        // console.log(s, path);
                        window.location.href = path;
                      }}
                      className="text-center"
                    >
                      {s?.menu_item_image && (
                        <span className="icon-country block py-[2px] px-0 text-xs text-[#828282]">
                          <img
                            src={Utils.formatURL(
                              `table_properties/modules/menu_item/menu_item_image/${s?.menu_item_id}/${s?.menu_item_image}?s=xs`
                            )}
                            alt={s.title_lang && s.title_lang[language]}
                            width="16"
                            height="16"
                          />
                        </span>
                      )}
                      {s.title_lang && s.title_lang[language]}
                    </Link>
                  </li>
                );

              return (
                <li key={s.menu_item_id}>
                  <Link
                    aria-label="link"
                    to={path}
                    title={s.title_lang && s.title_lang[language]}
                    className="text-center"
                  >
                    {s?.menu_item_image && (
                      <span className="icon-country block py-[2px] px-0 text-xs text-[#828282]">
                        <img
                          src={Utils.formatURL(
                            `table_properties/modules/menu_item/menu_item_image/${s?.menu_item_id}/${s?.menu_item_image}?s=xs`
                          )}
                          alt={s.title_lang && s.title_lang[language]}
                          width="16"
                          height="16"
                        />
                      </span>
                    )}
                    {s.title_lang && s.title_lang[language]}
                  </Link>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    );
  });
});

export const SearchBar = memo((props) => {
  const dispatch = useDispatch();
  const pathSearch = useSelector(selectProductPath);
  const { setIsModalOpen } = useGlobalContext();
  const ref = useRef(null);
  const [isSearchModalOpen, setSearchModalOpen] = useState(false);
  const [v, setV] = useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  useOnClickOutside(ref, () => setSearchModalOpen(false));
  const autoRef = useRef(null);

  const list = useSelector(selectProductsForSearch);

  useEffect(() => {
    if (isSearchModalOpen === true) {
      setIsModalOpen(true);

      setTimeout(() => {
        const input = document.getElementById("autocomplete-search-input");
        input && input.focus();
      }, 200);
    }

    if (isSearchModalOpen === false) {
      setIsModalOpen(false);
    }
  }, [isSearchModalOpen]);

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setSearchModalOpen(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const handleOpen = () => {
    if (inputValue.length > 0) {
      setOpen(true);
    }
  };
  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
    if (newInputValue.length > 0) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  };

  return (
    <div
      className={`header-search-iconbar inline-block align-middle pl-[17px] text-[17px] leading-none${
        props.className ? ` ${props.className}` : ""
      }`}
      style={props.style}
    >
      <Link
        to="#"
        aria-label="search"
        className="search-form-icon leading-[17px]"
        onClick={(e) => e.preventDefault()}
      >
        <i
          className={`feather-search px-0 inline-block${
            props.className ? ` ${props.className}` : ""
          }`}
          onClick={() => {
            dispatch(getProductsSearchBar());
            setSearchModalOpen(true);
            setTimeout(() => {
              autoRef.current && autoRef.current.focus();
            }, 300);
          }}
        ></i>
      </Link>

      {/* Search pop-up model Start */}
      <div className={`form-wrapper ${isSearchModalOpen ? " show" : ""}`}>
        <button
          title="Close"
          type="button"
          className="search-close font-serif"
          onClick={() => setSearchModalOpen(false)}
        >
          ×{" "}
        </button>

        <div id="search-form" className="search-form text-start w-full">
          <div className="search-form-box sm:!w-full mx-10">
            <span className="search-label font-medium text-spanishgray text-sm font-serif uppercase block mb-2">
              {dispatch(t("WEBSITE.search_title"))}
            </span>

            <Autocomplete
              ref={autoRef}
              id="autocomplete-search-input"
              options={list}
              autoFocus
              open={open}
              onOpen={handleOpen}
              onClose={() => setOpen(false)}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              value={v ? _.find(list, { product_id: v || v.product_id }) : null}
              onChange={(e, nv) => {
                if (nv) {
                  // setV(nv.product_id);
                  navigate(
                    `${pathSearch}/${
                      nv?.name
                        ? `p/${Utils.removeDiacritics(nv?.name)}-v${
                            nv?.product_id
                          }`
                        : ""
                    }`
                  );
                  setSearchModalOpen(false);
                  return;
                }

                // setV(nv);
                return;
              }}
              isOptionEqualToValue={(o, v) => v && o.product_id}
              getOptionLabel={(o) => o.name}
              renderOption={(_props, opt, { selected }) => (
                <Box
                  {..._props}
                  component="li"
                  sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                >
                  {opt.picture_src_SEO && (
                    <Avatar src={opt.picture_src_SEO} alt={opt.name} />
                  )}
                  <span className="ml-5">
                    <Typography>{opt.name}</Typography>
                  </span>
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder={dispatch(t("common.search"))}
                  label={dispatch(t("common.search"))}
                />
              )}
            />
          </div>
        </div>

        {/* {isSearchModalOpen && (
          <Formik
            initialValues={{ search: "" }}
            validationSchema={Yup.object().shape({
              search: Yup.string().required(
                dispatch(t("common.input_required"))
              ),
            })}
            onSubmit={async (values, actions) => {
              await new Promise((r) => setTimeout(r, 500));
              // actions.resetForm();
              setSearchModalOpen(false);
              navigate(`${pathSearch}/s/${values.search}`, {
                state: { search: values },
              });
            }}
          >
            <Form
              role="search"
              method="get"
              id="search-form"
              className="search-form text-start w-full"
              ref={ref}
            >
              <div className="search-form-box sm:!w-full mx-10">
                <Input
                  showErrorMsg={false}
                  className="search-input font-serif text-darkgray relative border-b border-solid border-darkgray"
                  name="search"
                  type="text"
                  label={
                    <span className="search-label font-medium text-spanishgray text-sm font-serif uppercase block">
                      {dispatch(t("WEBSITE.search_title"))}
                    </span>
                  }
                  placeholder={dispatch(t("WEBSITE.search_placeholder"))}
                />
                <button
                  type="submit"
                  className="search-button absolute top-1/2 right-0"
                >
                  <i
                    className="feather-search text-darkgray"
                    aria-hidden="true"
                  ></i>
                </button>
              </div>
            </Form>
          </Formik>
        )} */}
      </div>
    </div>
  );
});

Header.defaultProps = {
  topSpace: {
    desktop: false,
  },
};

Header.propTypes = {
  type: PropTypes.string,
  topSpace: PropTypes.object,
};

HeaderNav.defaultProps = {
  fluid: "lg",
  theme: "dark",
  menu: "light",
  expand: "lg",
};

HeaderNav.propTypes = {
  fluid: PropTypes.string,
  theme: PropTypes.string,
  bg: PropTypes.string,
  className: PropTypes.string,
};

Menu.defaultProps = {
  data: [],
};

export default Header;
