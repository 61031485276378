import {
  createAsyncThunk,
  createEntityAdapter,
  createSelector,
  createSlice,
} from "@reduxjs/toolkit";
import _ from "lodash";
import { getCategories } from "./categoriesSlice";
import { ProductModel, PropsModel } from "../model/ProductModel";
import fetch from "../../../../context/AxiosContext";
import { selectCurrentLanguage } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";

const listAdapter = createEntityAdapter({
  selectId: (data) => data.product_id,
});

const searchAdapter = createEntityAdapter({
  selectId: (data) => data.product_id,
});

const initialState = listAdapter.getInitialState({
  status: "pending",
  product: null,
  filters: {},
  filter: {},
  search: "",
  statusprod: "pending",
  prods_search: searchAdapter.getInitialState(),
});

export const getProduct = createAsyncThunk(
  "products/list/getProduct",
  async (id, { dispatch, getState }) => {
    try {
      const lang = selectCurrentLanguage(getState());

      const res = await fetch.get(`site/public/product/${id}?lang=`+lang);

      if (res.result === "error") return Promise.reject(res);

      if (_.isEmpty(res.result)) return Promise.reject(res);

      const item = ProductModel(res.result);

      const { pictures, properties } = item;
      // const { general_feature, ...rest } = properties;

      const general_feature = properties.find(
        (p) => p.property_tag === "general_feature"
      );
      const rest = properties.filter(
        (p) => p.property_tag !== "general_feature"
      );

      return {
        ...item,
        pictures,
        properties: rest,
        general_feature,
        slide: pictures.filter((p) => p.segment.includes("site:slide")),
        images: pictures.filter((p) => p.segment.includes("site:images")),
      };
    } catch (error) {
      console.error(error);
    }
  }
);

export const getProducts = createAsyncThunk(
  "products/list/getProducts",
  async (__, { dispatch, getState }) => {
    try {
      const lang = selectCurrentLanguage(getState());
      const res = await fetch.get(`site/public/product/search`);

      if (res.result === "error") return Promise.reject(res);

      if (_.isEmpty(res.result)) return Promise.reject(res);

      const items = await res.result
        .map((i) => ProductModel(i))
        .map((i) => {
          const { picture_src_SEO, picture_src, name_lang, name } = i;

          const n = name_lang[lang] || "";

          return {
            ...i,
            picture_src_SEO: `${picture_src_SEO}?s=sm`,
            picture_src: `${picture_src}?s=sm`,
            name: n,
          };
        });

      return items;
    } catch (error) {
      console.error(error);
    }
  }
);

export const getProductsSearchBar = createAsyncThunk(
  "products/list/getProductsSearchBar",
  async (__, { dispatch, getState }) => {
    try {
      const lang = selectCurrentLanguage(getState());
      const res = await fetch.get(`site/public/product/search`);

      if (res.result === "error") return Promise.reject(res);

      if (_.isEmpty(res.result)) return Promise.reject(res);

      const items = await res.result
        .map((i) => ProductModel(i))
        .map((i) => {
          const { picture_src_SEO, picture_src, name_lang, name } = i;

          const n = name_lang[lang] || "";

          return {
            ...i,
            picture_src_SEO: `${picture_src_SEO}?s=sm`,
            picture_src: `${picture_src}?s=sm`,
            name: n,
          };
        });

      return items;
    } catch (error) {
      console.error(error);
    }
  }
);

export const searchProducts = createAsyncThunk(
  "products/list/searchProducts",
  async (data, { dispatch, getState }) => {
    try {
      let obj = {};
      const { search, ...rest } = data;

      dispatch(setFilter({ ...data }));
      const lang = selectCurrentLanguage(getState());

      if (search) obj["q"] = search;

      if (!_.isEmpty(rest)) obj = { ...obj, ...rest };

      const res = await fetch.post(`/site/public/product/search`, obj);

      if (res.result === "error") return Promise.reject(res);

      if (_.isEmpty(res.result)) return [];

      const items = await res.result
        .map((i) => ProductModel(i))
        .map((i) => {
          const { picture_src_SEO, picture_src } = i;
          return {
            ...i,
            picture_src_SEO: `${picture_src_SEO}?s=sm`,
            picture_src: `${picture_src}?s=sm`,
          };
        });

      return items;
    } catch (error) {
      console.error(error);
    }
  }
);

export const searchByCategories = createAsyncThunk(
  "products/list/searchByCategories",
  async ({ data, id }, { dispatch, getState }) => {
    try {
      let obj = {};
      const { search, ...rest } = data;

      dispatch(setFilter({ ...data }));

      if (search) obj["q"] = search;

      if (!_.isEmpty(rest)) obj = { ...obj, ...rest };

      const res = await fetch.post(`/site/public/categories/${id || ""}`, obj);

      if (res.result === "error") return Promise.reject(res);

      if (_.isEmpty(res.result)) return [];

      const { products } = res.result;

      if (_.isEmpty(products)) return [];

      const items = await products
        .map((i) => ProductModel(i))
        .map((i) => {
          const { picture_src_SEO, picture_src } = i;
          return {
            ...i,
            picture_src_SEO: `${picture_src_SEO}?s=sm`,
            picture_src: `${picture_src}?s=sm`,
          };
        });

      return items;
    } catch (error) {
      console.error(error);
    }
  }
);

export const getFilters = createAsyncThunk(
  "products/list/getFilters",
  async (__, { dispatch, getState }) => {
    try {
      const lang = selectCurrentLanguage(getState());
      const res = await fetch.get(`/site/public/product/filter?lang=`+lang);

      if (res.result === "error") return Promise.reject(res);

      if (_.isEmpty(res.result)) return {};

      const obj = {};

      res.result
        .map((i) => PropsModel(i))
        .map((d) => {
          const { name, property_tag, props_list, table_name } = d;

          const td = table_name.replace("dt_", "");

          if (_.isEmpty(props_list)) {
            obj[property_tag] = [];
            return;
          }

          if (!_.isEmpty(props_list)) {
            const c = props_list.map((p) => {
              const { picture: pic, name_lang, props_list, ...rest } = p;

              let label = name_lang ? name_lang[lang] : "";
              let value = "";
              let picture = null;
              let children = [];
              let prefix = !_.isEmpty(props_list);

              Object.keys(rest).map((k) => {
                if (k.includes(`_id`)) {
                  value = prefix ? `group_item_${rest[k]}` : rest[k];
                  picture = pic
                    ? Utils.formatURL(
                        `data_table/${k.replace("_id", "")}/picture/${
                          rest[k]
                        }/${pic}?s=xs`
                      )
                    : null;
                }
              });

              if (_.isEmpty(props_list))
                return {
                  label,
                  value,
                  picture,
                };

              children = props_list.map((c) => {
                const { picture: pic, name_lang, props_list, ...rest } = c;

                let label = name_lang ? name_lang[lang] : "";
                let value = "";
                let picture = null;

                Object.keys(rest).map((k) => {
                  if (k.includes(`${td}_id`)) {
                    value = rest[k];
                    picture = pic
                      ? Utils.formatURL(
                          `data_table/${k.replace("_id", "")}/picture/${
                            rest[k]
                          }/${pic}?s=xs`
                        )
                      : null;
                  }
                });

                return {
                  label,
                  value,
                  picture,
                };
              });

              return {
                label,
                value,
                picture,
                children,
              };
            });

            obj[property_tag] = c;
            return;
          }
        });

      return obj;
    } catch (error) {
      console.error(error);
    }
  }
);

export const {
  selectAll: selectProducts,
  selectById,
  selectEntities,
  selectIds,
  selectTotal,
} = listAdapter.getSelectors((state) => state.products.list);

export const { selectAll: selectProductsForSearch } =
  searchAdapter.getSelectors((state) => state.products.list.prods_search);

export const selectFilter = ({ products }) => products.list.filter;
export const selectSearch = ({ products }) => products.list.search;

export const filteredList = createSelector(
  [selectFilter, selectSearch],
  (f, s) => {
    try {
      // let obj = {};
      // let path = "";
      // if (s) {
      //   obj["q"] = s;
      //   path = `q=${s}`;
      // }
      // if (!_.isEmpty(f)) obj = { ...obj, ...f };
      // const res = await fetch.post(`/site/public/product/search`, obj);
      // if (res.result === "error") return Promise.reject(res);
      // if (_.isEmpty(res.result)) return [];
      // const items = await res.result
      //   .map((i) => ProductModel(i))
      //   .map((i) => {
      //     return { ...i };
      //   });
      // console.log(res, items);
      // return items;
    } catch (error) {}
  }
);

const listSlice = createSlice({
  name: "products/list",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = {
        ...state.filter,
        ...action.payload,
      };
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
  },
  extraReducers: (b) => {
    b.addCase(getCategories.fulfilled, (state, action) => {
      const { products } = action.payload;
      listAdapter.setAll(state, products);
    })
      .addCase(getProduct.pending, (state, action) => {
        state.statusprod = "pending";
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.product = action.payload;
        state.statusprod = "fulfilled";
      })
      .addCase(searchProducts.fulfilled, (state, action) => {
        listAdapter.setAll(state, action.payload);
      })
      .addCase(searchByCategories.fulfilled, (state, action) => {
        listAdapter.setAll(state, action.payload);
      })
      .addCase(getProducts.fulfilled, (state, action) => {
        listAdapter.setAll(state, action.payload);
      })
      .addCase(getFilters.fulfilled, (state, action) => {
        state.filters = action.payload;
      });
    b.addCase(getProductsSearchBar.fulfilled, (state, action) => {
      searchAdapter.setAll(state.prods_search, action.payload);
    });
  },
});

export const { setFilter, setSearch } = listSlice.actions;

export const selectProductStatus = ({ products }) => products.list.statusprod;
export const selectProductItem = ({ products }) => products.list.product;
export const selectFilters = ({ products }) => products.list.filters;

export default listSlice.reducer;
